body {
    position: relative;
}

.table-of-contents-wrapper {
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
   /* max-height: calc(100vh - 90px);
    top: 80px;*/

    max-height: 100vh;
    top: 1rem;
    border-left: 1px solid #dadde1;
    padding: 0.5rem 0 0.5rem 1rem;


    .table-of-contents-wrapper-summary {
        color: black;
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: .5rem;
        margin-top: 0;
        position: relative;
        text-transform: uppercase;

        &:before {
            border: solid #555555;
            border-width: 0 2px 2px 0;
            content: "";
            left: 0;
            padding: 2px;
            position: absolute;
            top: .6rem;
            -webkit-transform: rotate( -45deg );
            transform: rotate( -45deg );
            -webkit-transition: -webkit-transform .1s ease-out;
            transition: -webkit-transform .1s ease-out;
            transition: transform .1s ease-out;
            transition: transform .1s ease-out,-webkit-transform .1s ease-out;
        }

        &::marker {
            color: transparent;
            opacity: 0;
        }
    }


    &[open] .table-of-contents-wrapper-summary:before {
        -webkit-transform: rotate( 45deg );
        transform: rotate( 45deg );
    }

    .gotop {
        padding-top: .8rem;
        font-size: .8rem;
        padding-left: .6em;
    }

    & > nav > ul {
        padding-left: 0;
    }

    & > nav > ul > li {
        margin-left: 0;
    }


    ul {
        list-style-type: none;
        padding-left: 0.5rem;
        font-size: .9rem;
        margin-bottom: 0;

        li {
            margin: .65rem 0 .65rem .5rem;
            line-height: 1.1rem;

            a {
                padding: 0 0 0 1rem;
                position: relative;
                /*color: #606770;*/
                color: #333;


                &.active,
                &:hover {
                    color: #02acbe;
                    text-decoration: none;
                }

                &.active::before {
                    content: "";
                    position: absolute;
                    background-color: #02acbe;
                    height: 100%;
                    width: 3px;
                    left: -0.1rem;
                }
            }
        }
    }


    &::-webkit-scrollbar-thumb {
        background: #dadde1;
        border-radius: .25rem;
    }

    &::-webkit-scrollbar {
        width: .5rem;
    }
}