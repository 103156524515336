.article-actions {
    position: relative;

    .article-actions-item {
        padding-right: 1rem;

        .bi {
            padding-right: 0.25rem;
        }

        .btn.btn-link {
            line-height: 1.67;
            padding: 0;
            border-width: 0;
            display: inline;
            vertical-align: unset;
        }
    }

    .toast-container {
        position: absolute;
        top: 2.8rem;
        right: 0;
        z-index: 1;
        white-space: nowrap;

        .toast {
            border: none;
        }
    }
}
