a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;

    img.logo {
        height: 32px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
    background-color: #337ab7;
}

.search-input-group {
    position: relative;

    .search-input {
        border: none;
    }

    .input-group-append .btn.search-reset {
        background: white;
        color: black;
        position: absolute;
        right: 2.6rem;
        z-index: 4;
        opacity: 0.3;

        &:hover,
        &:focus,
        &:active {
            opacity: 1;
        }
    }

    .input-group-append .btn.search-btn {
        background: #0063ae;
        border-color: #0063ae;
        color: white;

        &:hover {
            background: #005a9e;
            border-color: #005a9e;
        }
    }
}