.book-card {
    border-left-width: 1.2rem;
    border-left-style: solid;
    border-left-color: #007bff;
}

.card-link .card:hover {
    background-color: #F9F9F9;
}

a.card-link,
a.card-link:link,
a.card-link:visited,
a.card-link:hover,
a.card-link:active {
    text-decoration: none;
}

a.card-link:hover .card-title,
a.card-link:active .card-title {
    text-decoration: underline;
}
