.article-feedback {
    background-color: #F9F9F9;
    border: 1px solid rgba(0,0,0,.1);

    .vote {
        opacity: .8;
        padding: 0 1rem;
        font-size: 2rem;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &.upvote {
            color: green;
        }

        &.downvote {
            color: red;
        }
    }
}