.article-banner {

    .article-banner-item {
        padding-right: 1rem;

        .bi {
            padding-right: 0.25rem;
        }

        .btn.btn-link {
            line-height: 1.67;
            padding: 0;
            border-width: 0;
            display: inline;
            vertical-align: unset;
        }
    }
}
