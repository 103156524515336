html {
    font-size: 14px;
}

body {
    line-height: 1.67;
}

.btn[disabled] {
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.bi.bi-flipped::before {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.lh-1 {
    line-height: 1rem;
}