ul.article-breadcrumb {
    display: inline;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline;
        padding: 0;
        margin: 0;
        font-weight: bold;
    }

    li::before {
        content: "›";
        margin: 0 0.5rem;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 0;
    }

    li:first-child::before {
        display: none;
    }
}
