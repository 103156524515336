.user-status {
    .dropdown-menu {
        font-size: 14px;
        min-width: 260px;
    }

    .dropdown-menu .avatar {
        display: block;
        margin: 0 auto;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .darkmode-toggle-button .bi {
        font-size: 1.3rem;
        line-height: 0;
        top: 3px;
        left: -3px;
        position: relative;
    }
}