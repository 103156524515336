html.darkmode {

    $dark-light-bg-color: #242526;
    $dark-medium-bg-color: #161b22;
    $dark-bg-color: black;
    $dark-light-border-color: #30363d;
    $dark-medium-border-color: #21262d;

    body {
        background-color: $dark-bg-color;
        color: white;
    }

    * {
        transition: background .2s;
    }

    .navbar {
        background-color: $dark-medium-bg-color;
        color: #fff;

        .navbar-brand {
            color: #fff;
        }

        border-width: 0 !important;

        input[type=search] {
            color: white;
            background-color: $dark-bg-color;
            border-color: $dark-medium-border-color;

            &:focus {
                color: black;
                background-color: white;
                border-color: white;

                &::placeholder {
                    color: inherit;
                    opacity: inherit;
                }
            }

            &::placeholder {
                color: white;
                opacity: 1;
            }
        }
    }

    .card-link .card:hover {
        background-color: $dark-medium-border-color;
    }

    .bg-light {
        background-color: inherit !important;
    }

    .markdown-viewer {
        color: white;

        pre {
            background-color: #292d3e;

            code {
                color: rgba(255, 255, 255, .9);
            }
        }

        .table, .table th, .table td {
            color: white;
            border: 1px solid #606770;
        }

        blockquote {
            border-left: 5px solid #606770;
        }
        /* Variations */
        .callout {
            color: white;
            border-width: 0;

            * {
                color: white;
            }

            &.callout-success {
                background-color: #3c763d;
            }

            &.callout-info {
                background-color: #1b809e;
            }

            &.callout-warning {
                background-color: #aa6708;
            }

            &.callout-danger {
                background-color: #ce4844;
            }
        }

        h1, h2, h3, h4, h5, h6 {
            color: #CCC;
        }
    }

    hr {
        border-top: 1px solid $dark-light-border-color;
    }

    .table-of-contents-wrapper {
        border-left-color: #606770;

        .table-of-contents-wrapper-summary {
            color: white;

            &:before {
                border-color: white;
            }
        }

        ul li a.nav-link {
            color: #CCCCCC;
        }

        ul li a.active::before {
            background-color: #f9826c;
        }
    }

    .sidebar {
        background-color: black;
        border-right-color: $dark-light-border-color;

        summary {
            color: white;
            background: black;

            &:before {
                border-color: white;
            }
        }

        .sidebar-content {
            &::-webkit-scrollbar-thumb {
                background: $dark-light-bg-color;
            }
        }

        .menu-root {
            border-bottom-color: $dark-light-border-color;

            a, a:hover {
                color: white;
            }
        }
    }

    .table-of-contents-wrapper {
        border-left-color: $dark-light-border-color;

        &::-webkit-scrollbar-thumb {
            background: $dark-light-bg-color;
        }
    }

    .article-feedback {
        background-color: $dark-medium-bg-color;
        border-color: $dark-light-border-color;
    }

    .spinner-grow span {
        color: $dark-light-border-color !important;
    }

    .list-group-item {
        color: white;

        &:not([class*="active"]) {
            background-color: $dark-medium-bg-color;
        }
    }

    .card {
        background-color: $dark-medium-bg-color;
        border-color: $dark-light-border-color;
    }
}
