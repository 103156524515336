.search-result-link {
    font-size: 1.3rem;
    line-height: 1rem;
}
.search-result-details {
    line-height: 1.2rem;
}

.search-filter-item {
    padding: 0.35rem 0.6rem;
}

.search-filter-item.active .badge-light {
    color: #007bff;
}