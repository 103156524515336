.markdown-viewer {
    color: #0e1e25de;

    code {
        padding: 2px 4px;
        font-size: 90%;
        color: #c7254e;
        background-color: #f9f2f4;
        border-radius: 4px;
        white-space: pre-wrap;
    }

    pre {
        font-size: 13px;
        line-height: 1.42857;
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;
        color: #333333;
        overflow: auto;
        /******/

        padding: 1em;
        margin: .5em 0;
        overflow: auto;
        background: rgba(0, 0, 0, .06);
        border-radius: .25rem;

        code {
            padding: 0;
            font-size: inherit;
            color: inherit;
            background-color: transparent;
            border-radius: 0;
            color: rgba(0, 0, 0, .9);
        }
    }

    img {
        max-width: 100%;
    }
    /** Heading anchors **/

    h1, h2, h3, h4, h5, h6 {
        a.heading-anchor {
            color: #02acbe;
            text-decoration: none;
            padding-left: 0.5rem;
            opacity: 0;
            -webkit-transition: opacity .2s;
            transition: opacity .2s;
        }

        &:hover a.heading-anchor {
            opacity: 1;
        }
    }

    h1 {
        font-size: 1.7rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.3rem;
    }

    h4 {
        font-size: 1.1rem;
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 1rem;
        color: #808080;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
        margin: 2.5rem 0 1.25rem 0;
        color: #00456e;
    }

    p, pre, ol, ul, blockquote {
        margin: 16px 0;
    }

    ol > li > ol,
    ol > li > ul,
    ul > li > ol,
    ul > li > ul {
        margin: 0;
    }

    blockquote {
        padding: 10px 20px;
        margin: 0 0 20px;
        border-left: 5px solid #CCC;

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    b, strong {
        font-weight: 500;
    }

    a.external-link::after {
        // copied from https://icons.getbootstrap.com/icons/box-arrow-up-right/
        content: "\f1c5";
        padding-left: 0.33rem;
        font-size: 0.7rem;
        display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: 700 !important;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /*vertical-align: -.125em;*/
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    table {
        margin: 16px 0 !important;

        td {
            min-width: 50px;
            max-width: 1000px;
        }
    }

    .table, .table th, .table td {
        border: 1px solid #dee2e6;
    }

    .table thead th {
        vertical-align: top;
    }

    ul, ol {
        padding-left: 2em;

        li {
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    ol.contains-task-list,
    ul.contains-task-list {
        padding-left: 7px;

        ul.contains-task-list,
        ol.contains-task-list {
            padding-left: 2em;
        }

        li {
            list-style-type: none;

            input[type=checkbox] {
                vertical-align: middle;
                margin: 4px;
                float: none;
            }
        }
    }
    /** Callouts **/

    .callout {
        padding: 20px;
        margin: 20px 0;
        border: 1px solid #eee;
        border-left-width: 5px;
        border-radius: 3px;
        color: black;

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

        code {
            border-radius: 3px;
            border: 1px solid #ddd;
        }
        /* Tighten up space between multiple callouts */
        & + .callout {
            margin-top: -5px;
        }
        /* Variations */
        &.callout-success {
            border-color: #d6e9c6;
            background-color: #dff0d8;
            border-left-color: #3c763d;

            h1, h2, h3, h4, h5, h6 {
                color: #3c763d;
            }
        }

        &.callout-info {
            border-color: #bce8f1;
            background-color: #d9edf7;
            border-left-color: #1b809e;

            h1, h2, h3, h4, h5, h6 {
                color: #1b809e;
            }
        }

        &.callout-warning {
            border-color: #faebcc;
            background-color: #fcf8e3;
            border-left-color: #aa6708;

            h1, h2, h3, h4, h5, h6 {
                color: #aa6708;
            }
        }

        &.callout-danger {
            border-color: #ebccd1;
            background-color: #f2dede;
            border-left-color: #ce4844;

            h1, h2, h3, h4, h5, h6 {
                color: #ce4844;
            }
        }
    }
}
