.sidebar {
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 100vh;
    border-right: 1px solid rgba(0,0,0,.1);
    background-color: #F9F9F9;
    padding: 0;

    .sidebar-content {
        height: 100%;
    }

    details {
        position: relative;
    }

    details summary {
        color: black;
        background: #F9F9F9;
        margin: 0;
        padding: 0.3em 0 0.3em 0.65em;
        line-height: 1.2rem;
        list-style: none;

        &:before {
            border: solid #555555;
            border-width: 0 2px 2px 0;
            content: "";
            left: -0.3rem;
            padding: 2px;
            position: absolute;
            top: 0.75rem;
            -webkit-transform: rotate( -45deg );
            transform: rotate( -45deg );
            -webkit-transition: -webkit-transform .1s ease-out;
            transition: -webkit-transform .1s ease-out;
            transition: transform .1s ease-out;
            transition: transform .1s ease-out,-webkit-transform .1s ease-out;
        }

        &::marker {
            color: transparent;
            opacity: 0;
        }
    }

    details[open] summary:before {
        -webkit-transform: rotate( 45deg );
        transform: rotate( 45deg );
        top: 0.6rem;
        left: -0.2rem;
    }

    details ul li {
        margin: 0.6em 0.3em 0.6em 0em;
        line-height: 1.1rem;
    }

    details ul {
        padding-left: 0.7em;
    }

    ul li.active {
        font-weight: 600;

        a {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 3px;
                left: -0.6rem;
                top: 0;
                background-color: #007bff;
            }
        }
    }

    ul {
        margin-left: 0.3rem;
    }

    details ul {
        margin-left: 0;
    }

    details details {
        padding-left: 6px;
    }

    .menu-root {
        padding: 0.75rem 0.5rem;
        border-bottom: 1px solid #dddddd;

        .bi {
            font-size: 1.1rem;
        }
    }

    .menu-scrollable {
        height: 100%;
        overflow-y: auto;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        margin-left: 0.4rem;
        margin-right: 4px;
        padding-top: 0.3rem;
        padding-bottom: 4rem;
        padding-left: 0.3rem;
        font-size: .875rem;

        &::-webkit-scrollbar-thumb {
            background-color: #CCCCCC;
            border-radius: .25rem;
            display: none;
        }

        &:hover::-webkit-scrollbar-thumb {
            display: block;
        }

        &::-webkit-scrollbar {
            width: .5rem;
        }
    }
}

.menu-root {
    padding: 0.75rem 0.5rem;
    margin-left: -15px;
    margin-right: -15px;

    .bi {
        font-size: 1.1rem;
    }

    a, a:hover, .btn {
        color: white;
    }
}

@media print {
    .article {
        max-width: 100% !important;
        flex: none !important;
        padding: 1rem !important;
    }
}